<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <div class="header-search">
      <el-form ref="form" inline :model="searchData" label-width="">
        <el-form-item label="账号">
          <el-input v-model="searchData.account" size="small" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="会员等级" label-width="100px">
          <el-select v-model="searchData.member_id" clearable @clear="getList" placeholder="请选择">
            <el-option v-for="item in memberList" :key="item.id" :label="item.title" :value="item.id"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="手机号">
          <el-input v-model="searchData.mobile" size="small" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="注册时间">
          <el-date-picker
            style="width: 250px"
            size="small"
            v-model="searchData.timeSlot"
            value-format="yyyy-MM-dd"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="getList"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label=" ">
          <el-button type="primary" size="small" icon="el-icon-search" @click="getList()">搜索</el-button>
          <el-button type="info" size="small" icon="el-icon-refresh" @click="reset">重置</el-button>
          <el-button style="margin-bottom: 20px" type="primary" icon="el-icon-plus" size="small" @click="goAdd"> 添加</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-divider></el-divider>
    <tp-table
      :isNeedSerialNumber="true"
      :tableData="list"
      :columns="columns"
      :totalNum="total"
      :current-page.sync="currentPage"
      :pageSize.sync="page_size"
    />
  </div>
</template>

<script>
import { getList, getAllMember, delAPI } from './api'

const columns = [
  // {
  //     label: '会员名称',
  //     prop: 'member_name',
  //     minWidth: '120',
  //     customRender(h, row) {
  //         return <p>{row['member_name'] || '-'}</p>
  //     }
  // },
  {
    label: '账号',
    prop: 'account',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['account']}</p>
    }
  },
  {
    label: '会员等级',
    prop: 'member_name',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['member_name'] || '-'}</p>
    }
  },
  // {
  //     label: '头像',
  //     prop: 'face',
  //     minWidth: '100',
  //     customRender(h, row) {
  //         return <el-image style="width: 60px; height: 60px;margin-top:5px;" src={row['face']} fit="fit"></el-image>
  //     }
  // },
  {
    label: '手机号码',
    prop: 'mobile',
    minWidth: '120',
    customRender(h, row) {
      return <p>{row['mobile']}</p>
    }
  },
  {
    label: '注册时间',
    prop: 'created_at',
    minWidth: '160',
    customRender(h, row) {
      return <p>{row['created_at'] || row['mini_user']?.['created_at']}</p>
    }
  },
  {
    label: '最后登录时间',
    prop: 'last_login_at',
    minWidth: '160',
    customRender(h, row) {
      return <p>{row['last_login_at']}</p>
    }
  },
  // {
  //     label: '最后登录ip',
  //     prop: 'last_login_ip',
  //     minWidth: '160',
  //     customRender(h, row) {
  //         return <p>{row['createTime']}</p>
  //     }
  // },
  {
    label: '操作',
    fixed: 'right',
    minWidth: '100',
    customRender(h, row) {
      return (
        <div>
          <el-link
            type="primary"
            onClick={() =>
              this.$router.push({
                name: 'MemberEdit',
                params: { id: row['id'] || undefined }
              })
            }
          >
            编辑
          </el-link>
          <el-link style="margin-left:10px" type="danger" onClick={() => this.handleDel(row['id'])}>
            删除
          </el-link>
        </div>
      )
    }
  }
]
export default {
  name: 'List',

  data() {
    return {
      columns,
      currentPage: 1,
      page_size: 10,
      total: 0,
      list: [],
      memberList: [
        {
          id: 0,
          title: '请选择'
        }
      ],
      searchData: {
        account: '',
        realname: '',
        mobile: '',
        identityNo: '',
        timeSlot: [],
        leftCreateDate: '',
        rightCreateDate: '',
        member_id: 0
      }
    }
  },
  provide() {
    return {
      context: this
    }
  },
  watch: {
    currentPage() {
      this.getList()
    },
    page_size() {
      this.getList()
    }
  },
  mounted() {
    this.getList()
    this.getAllMember()
  },

  methods: {
    goAdd() {
      this.$router.push({ name: 'MemberEdit' })
    },
    async getList() {
      const { account, userName, mobile, identityNo, timeSlot, member_id } = this.searchData
      let params = { page: this.currentPage, page_size: this.page_size, account, userName, mobile, identityNo, member_id }

      if (timeSlot && timeSlot.length > 0) {
        params.leftCreateDate = timeSlot[0] || ''
        params.rightCreateDate = timeSlot[1] || ''
      }

      const res = await getList(params)
      this.list = res.data || []
      this.total = res.total || 0
    },
    goEdit(id) {
      this.$router.push({ name: 'MemberEdit', params: { id: id || undefined } })
    },
    async getAllMember() {
      this.memberList = await getAllMember({})
    },
    // 加入黑/白名单
    // changeStatus({ id, status }) {
    //   console.log(id, status)
    //   let data = { id, riskLevel: status == 1 ? 2 : 1 }
    //   this.$confirm(`是否将该用户加入${status == 1 ? '黑' : '白'}名单, 是否继续?`, '提示', {
    //     confirmButtonText: '确定',
    //     cancelButtonText: '取消',
    //     type: 'warning'
    //   })
    //     .then(() => {
    //       addRosterAPI(data).then(() => {
    //         this.getList()
    //         this.$message.success('操作成功')
    //       })
    //     })
    //     .catch(() => {
    //       this.$message({
    //         type: 'info',
    //         message: '已取消操作'
    //       })
    //     })
    // },
    reset() {
      Object.assign(this._data.searchData, this.$options.data().searchData)
      this.getList()
    },
    handleDel(id) {
      this.$confirm('此操作将永久删除该会员, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delAPI(id).then(() => {
            this.getList()
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;

  .header-search {
    .el-form /deep/ {
      .el-form-item {
        margin-right: 35px;

        .el-input {
          width: 250px;
        }

        .el-date-editor {
          .el-range-separator {
            padding: 0;
          }

          .el-range-input {
            width: 35%;
          }
        }
      }
    }
  }

  .el-divider--horizontal {
    margin: 0 0 20px;
  }
}
</style>
